import { USER_DATA_COOKIE_NAME, HASHED_FINGERPRINT } from "@Constants/index";
import { FingerprintOptions, generateHashedFingerprint } from "hashed-device-fingerprint-js";
import Cookies from "js-cookie";
import { DeviceDataType } from "types/DeviceInfo";

export const deviceInfo = async (): Promise<DeviceDataType> => {
  // Get the cookie value and handle undefined case
  const cookieValue = Cookies.get(USER_DATA_COOKIE_NAME);
  const hashedFingerprintCookieValue = Cookies.get(HASHED_FINGERPRINT);
  const userIpInfo = cookieValue ? JSON.parse(cookieValue) : null;

  let deviceID = hashedFingerprintCookieValue;

  if (!deviceID) {
    const options: Partial<FingerprintOptions> = {
      useUserAgent: false, // Exclude the user agent (default: true)
      useLanguage: false, // Exclude the browser language
      saveToCookie: true, // Save the hash in a cookie (default: true)
      cookieExpiryDays: 356, // Set the cookie expiry to 1 year
    };

    deviceID = await generateHashedFingerprint(options);
    // Save the generated deviceID to a cookie
    Cookies.set(HASHED_FINGERPRINT, deviceID);
  }
  return {
    device_id: deviceID || null,
    device_data: {
      ip: userIpInfo?.user_ip || "",
      app_name: navigator.appName,
      app_version: navigator.appVersion,
      user_agent: navigator.userAgent,
      vendor: navigator.vendor,
      platform: navigator.platform,
      language: navigator.language,
      maxTouchPoints: navigator.maxTouchPoints,
      isMobile: /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent), // Check if it's a mobile device
    },
    device_type: "web",
  };
};
