// @ts-nocheck
import { DefaultSeoProps } from "next-seo";
import { Country } from "types/common";
import { UserProfile } from "types/users";
import { LocalesList } from "./constants";

const defaultSeo: DefaultSeoProps = {
  titleTemplate: "%s | Baims",
  description: "Baims Desc",
  languageAlternates: [
    {
      hrefLang: "en",
      href: "http://baims.com/",
    },
    {
      hrefLang: "ar",
      href: "http://baims.com/ar/",
    },
  ],
  additionalMetaTags: [
    {
      httpEquiv: "X-UA-Compatible",
      content: "IE=edge",
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0, maximum-scale=1, minimum-scale=1, user-scalable=no",
    },
    {
      name: "apple-itunes-app",
      content: "app-id=1274888149",
    },
    {
      name: "keywords",
      content:
        "دورات, دورة , دورات برمجة آيفون, دورات مجانية , كالكلس  , فيزياء ,كيمياء , دورات جامعة الكويت, دورة عن بعد, دورة مسجلة ، تعلم ,training courses,course search,courses,training,courses in kuwait,arabic courses,photography courses,engineering courses,دورات تدريبية,دورات تصوير,دورات محاسبة,الكويت,دورات تسويق,تطوير ذاتي , MOOC, online courses, online education, elearning, ios programlama, ücretsiz kurslar, matematik, fizik,kimya, öğrenme, öğrenmek, eğitim kursları, kurs arama, kurslar, eğitim, Kuveyt kursları, Suudi Arabistan Krallığı, Bahreyn, Ürdün, arapça kursları, türkçe kursları, fotoğrafçılık, mühendislik, muhasebe, pazarlama, MOOC,çevrimiçi kurslar, çevrimiçi eğitim, e-öğrenme, e-öğrenme",
    },
    {
      name: "reply-to",
      content: "social@baims.com",
    },
    {
      name: "author",
      content: "Baims",
    },
    {
      name: "designer",
      content: "Baims",
    },
    {
      name: "owner",
      content: "Baims",
    },
    {
      name: "revisit-after",
      content: "7 days",
    },
    {
      name: "medium",
      content: "image",
    },
    {
      property: "fb:app_id",
      content: "485726318457824",
    },
    {
      name: "_token",
      content: "QVhvHkZm8uc3xJ5FzzFQm80xykY5yupd1cO5FWni",
    },
    {
      name: "csrf-token",
      content: "QVhvHkZm8uc3xJ5FzzFQm80xykY5yupd1cO5FWni",
    },
  ],
  openGraph: {
    title: "Baims | Let's Learn",
    description: "Baims Desc",
    type: "website",
    locale: "en_KW",
    url: `${process.env.NEXT_PUBLIC_URL_BAIMS}`,
    site_name: "Baims",
    images: [
      {
        url: "https://s3.eu-central-1.amazonaws.com/baims/uploads/1647877893_logo_baims_blue_en.png",
        width: 476,
        height: 249,
        alt: "Baims",
      },
    ],
  },
  twitter: {
    handle: "@baims",
    site: "@baims",
    cardType: "summary_large_image",
  },
};

type SEO_Props = {
  titleTemplate: string;
  title: string;
  locale: string;
  asPath: string;
  description?: string;
  img?: string;
};

export const getPageSeo: ({ title, locale, asPath, description, titleTemplate, img }: SEO_Props) => DefaultSeoProps = ({
  title,
  titleTemplate,
  description,
  locale,
  asPath,
  img,
}) => ({
  title,
  titleTemplate,
  description,
  canonical: `${process.env.NEXT_PUBLIC_URL_BAIMS}${locale === "ar" ? "/ar" : ""}${asPath.split("?")[0]}`,
  languageAlternates: LocalesList.map((lang) => ({
    hrefLang: Boolean(lang.length) ? lang : "en",
    href: `${process.env.NEXT_PUBLIC_URL_BAIMS}${Boolean(lang.length) ? "/" : ""}${lang}${asPath}`,
  })),
  twitter: {
    handle: "@baims",
    site: "@baims",
    cardType: "summary_large_image",
  },
  openGraph: {
    title,
    description,
    type: "website",
    locale,
    url: `${process.env.NEXT_PUBLIC_URL_BAIMS}${locale === "ar" ? "/ar" : ""}${asPath}`,
    site_name: "Baims",
    images: [
      {
        url: Boolean(img)
          ? img
          : locale === "ar"
            ? "https://s3.eu-central-1.amazonaws.com/baims/uploads/1648030856_logo_baims_blue_ar.png"
            : "https://s3.eu-central-1.amazonaws.com/baims/uploads/1647877893_logo_baims_blue_en.png",
        width: 476,
        height: 249,
        alt: "Baims",
      },
    ],
  },
});

export const profileSeo: (profileData: UserProfile, countries: Country[], locale: string) => DefaultSeoProps = (
  profileData,
  countries,
  locale
) => {
  const title = `${profileData.username} | Baims`;

  const description = `${profileData.username} | Baims`;

  return {
    title,
    description,
    titleTemplate: "%s",
    languageAlternates: [
      {
        hrefLang: "en",
        href: `${process.env.NEXT_PUBLIC_URL_BAIMS}${profileData.username}`,
      },
      {
        hrefLang: "ar",
        href: `${process.env.NEXT_PUBLIC_URL_BAIMS}${locale === "ar" ? "/ar" : ""}${profileData.username}`,
      },
    ],
    openGraph: {
      title,
      description,
      type: "website",
      locale: "en_KW",
      url: `${process.env.NEXT_PUBLIC_URL_BAIMS}${profileData.username}`,
      site_name: profileData.name || profileData.username,
      images: [
        {
          url: profileData.profile_picture,
          alt: title,
        },
      ],
    },
    twitter: {
      handle: "@" + profileData.username,
      site: "@" + profileData.username,
      cardType: "summary_large_image",
    },
  };
};

export default defaultSeo;
