import { USER_DEVICE_ID } from "@Constants/index";
import { deviceInfo } from "@Utils/DeviceInfo";
import Axios from "lib/Axios";
import { APIResponse, Meta } from "types/ApiResponse";
import { Package } from "types/Package";
import { Subject } from "types/subject";
import {
  UserAPIData,
  UserProfile,
  UserSubscription,
  loginType,
  putProfileType,
  updateProfileImageType,
} from "types/users";
import { v4 as uuidv4 } from "uuid";

export const loginHandler = async (data: loginType) =>
  Axios.post<APIResponse<UserAPIData, Meta>>("/v1/login", {
    ...data,
    device_id: (await deviceInfo())?.device_id || "",
    device_data: (await deviceInfo())?.device_data || "",
  });

// POST device ID
export const postDeviceID = async () =>
  Axios.post("/v1/devices", {
    id: localStorage.getItem(USER_DEVICE_ID),
    os: "web",
    device_id: (await deviceInfo())?.device_id || "",
    device_data: (await deviceInfo())?.device_data || "",
  });

// Get profile API
export const getProfile = (token: string) =>
  Axios.get<APIResponse<UserProfile, Meta>>("/v1/profile", {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  });

// EDIT profile API
export const putProfile = (data: putProfileType, token: string) =>
  Axios.put<APIResponse<UserProfile, Meta>>(
    "/v1/profile",
    {
      ...data,
      is_agreed: true,
      fcm_token: uuidv4(),
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

// EDIT profile API
export const updateProfileImage = (data: updateProfileImageType) =>
  Axios.put<APIResponse<UserProfile, Meta>>("/v1/profile/media", {
    ...data,
    fcm_token: uuidv4(),
  });

// ! Users Profile
export const getUserProfile = (username: string | string[]) =>
  Axios.get<APIResponse<UserProfile, Meta>>(`/users/${username}`);

export const getContinueWatching = (token?: string) =>
  Axios.get<APIResponse<Subject[], {}>>("/v1/profile/subjects/continue-watching", {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  });

// Get User Subscriptions
export const _GetUserSubscriptions = (token?: string) =>
  Axios.get<APIResponse<UserSubscription[], Meta>>("/v1/profile/subscriptions", {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then(({ data }) => data.data);

export const _GetUserSubjectsSubscriptions = (token?: string) =>
  Axios.get<APIResponse<Subject[], Meta>>("/v1/profile/subject-subscriptions", {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then(({ data }) => data.data);

export const _GetUserPackagesSubscriptions = (token?: string) =>
  Axios.get<APIResponse<Package[], Meta>>("/v1/profile/package-subscriptions", {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then(({ data }) => data.data);

// test new secuirty algorithm
export const _GetChiperTEST = () => Axios.get(`/v1/cipher/test`);
